import { generateTotalMedsToPay } from "./cart"
import { getLabelFromDiscountTier } from "./customerTypes"
import { generatePrice } from "./computations"
import { getSKUSubstitution } from "./getSKUSubstitution"

export const parseMedicines = ({ medicines, products, type = "Code" }) => {
  const newLineChar = type === "Make" ? "<br/>" : "\n"

  let medicinesArray = ""

  let newProductTitles = getSKUSubstitution({
    type: "Regular",
  })

  medicines.map((medicine) => {
    let totalMedsToPay = generateTotalMedsToPay({
      medicine,
      quantity: medicine?.qty,
    })

    let discountType =
      medicine?.discountType === "Buy 6, Get 2" ? "6+2" : medicine?.discountType

    if (
      newProductTitles?.[medicine?.customerType]?.[medicine.productTitle]
        ?.length > 1
    ) {
      newProductTitles[medicine?.customerType][medicine.productTitle].map(
        (newProductTitle) => {
          medicinesArray += `${
            medicine?.customerType ||
            getLabelFromDiscountTier({
              medicine,
              discountTier: medicine?.discountTier,
            }) ||
            discountType ||
            "N/A"
          }, ${newProductTitle}, #${totalMedsToPay}, ${generatePrice(
            { ...medicine, productTitle: newProductTitle },
            products
          )}${newLineChar}`
          return null
        }
      )
    } else {
      medicinesArray += `${
        medicine?.customerType ||
        getLabelFromDiscountTier({
          medicine,
          discountTier: medicine?.discountTier,
        }) ||
        discountType ||
        "N/A"
      }, ${
        newProductTitles?.[medicine?.customerType]?.[medicine.productTitle] ||
        medicine.productTitle
      }, #${totalMedsToPay}, ${generatePrice(medicine, products)}${newLineChar}`
    }
    return null
  })

  medicinesArray += `Medicine assistance information: ${newLineChar}`

  medicines.map((medicine) => {
    let discountValues = medicine?.discountValues?.map((discountValue) => {
      let discountType =
        medicine?.discountType === "Buy 6, Get 2"
          ? "6+2"
          : medicine?.discountType
      let finalProductTitle = ""

      if (discountValue?.type)
        if (
          discountValue?.type === "quantity" &&
          discountValue?.freeProduct &&
          discountValue?.quantity >= 0
        ) {
          finalProductTitle = `${discountValue?.freeProduct} - Medicine Assistance`
          medicinesArray += `${
            medicine?.customerType ||
            getLabelFromDiscountTier({
              medicine,
              discountTier: medicine?.discountTier,
            }) ||
            discountType ||
            "N/A"
          }, ${
            newProductTitles?.[medicine?.customerType]?.[finalProductTitle] ||
            finalProductTitle
          }, #${discountValue?.quantity}, 0.00${newLineChar}`
        } else if (discountValue?.type === "amount") {
          finalProductTitle = `${medicine?.productTitle} - ${
            discountValue?.code?.startsWith("SCPWD")
              ? "SCPWD Discount"
              : `${
                  parseFloat(discountValue?.percentOff) % 1 === 0
                    ? parseFloat(discountValue?.percentOff).toFixed(0)
                    : parseFloat(discountValue?.percentOff).toFixed(2)
                }% Discount`
          }`

          if (
            newProductTitles?.[medicine?.customerType]?.[finalProductTitle]
              ?.length > 1
          ) {
            newProductTitles[medicine?.customerType][finalProductTitle].map(
              (newProductTitle) => {
                medicinesArray += `${
                  medicine?.customerType ||
                  getLabelFromDiscountTier({
                    medicine,
                    discountTier: medicine?.discountTier,
                  }) ||
                  discountType ||
                  "N/A"
                }, ${newProductTitle}, #${medicine?.qty}, -${(
                  parseFloat(discountValue?.percentOff / 100) *
                  generatePrice(
                    {
                      ...medicine,
                      productTitle: `${newProductTitle?.split(")")?.[0]})`,
                    },
                    products
                  )
                ).toFixed(2)}${newLineChar}`

                return null
              }
            )
          } else {
            medicinesArray += `${
              medicine?.customerType ||
              getLabelFromDiscountTier({
                medicine,
                discountTier: medicine?.discountTier,
              }) ||
              discountType ||
              "N/A"
            }, ${
              newProductTitles?.[medicine?.customerType]?.[finalProductTitle] ||
              finalProductTitle
            }, #${medicine?.qty}, -${(
              parseFloat(discountValue?.amount) / medicine?.qty
            ).toFixed(2)}${newLineChar}`
          }
        }
    })
    return null
  })

  return medicinesArray
}
