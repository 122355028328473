import React, { Fragment, useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"

import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import FormCheckbox from "../Elements/Form/FormCheckbox"
import EditDetailsButton from "../Summary/EditDetailsButton"
import OrderNotesSummary from "../Summary/OrderNotesSummary"
import MedsOrderedSummary from "../Summary/MedsOrderedSummary"
import OrderSubtotalSummary from "../Summary/OrderSubtotalSummary"
import PatientDetailsSummary from "../Summary/PatientDetailsSummary"
import UploadDocumentsSummary from "../Summary/UploadedDocumentsSummary"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"

import { sendRequest } from "../../services/order"
import { AppContext } from "../../context/AppContext"
import { shouldBeDisabled } from "./services/summary"
import { accumulatePrice } from "./services/computations"
import { parseMedicines } from "./services/parseMedicines"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { generateDeliveryFee } from "../Summary/services/deliveryFee"
import { orderTicketBody } from "./utils/templates/epharmacyOrderZendeskTemplate"
import { orderConfirmation } from "./utils/templates/epharmacyOrderEmailTemplate"
import termsAndConditionsData from "../Enrollment/utils/patientConsentAndAuthorization.json"
import { orderTextMessageTemplate } from "./utils/templates/orderTextMessageTemplate"

const agreeContactPersonBlurb =
  "If I or my authorized person to receive is not around, I authorize anyone in my delivery address to receive the medicines."

const Summary = ({ backRoute, baseRoute, route }) => {
  const products = useEpharmacyProducts()

  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const { epharmacy, documents, scpwdId, proofOfPayment } = state
  const { deliveryAddress, medicines, coupon, paymentOption } = epharmacy
  let uploadedDocuments = [...documents]
  if (scpwdId.front.path) uploadedDocuments.push(scpwdId.front)
  if (scpwdId.back.path) uploadedDocuments.push(scpwdId.back)
  if (proofOfPayment?.path) uploadedDocuments.push(proofOfPayment)

  let deliveryFee =
    generateDeliveryFee({
      medicines,
      dispensingChannel: epharmacy.courierOption,
      province: deliveryAddress?.province?.value,
      city: deliveryAddress?.city?.value,
      cartSize: accumulatePrice(medicines),
      paymentOption: paymentOption,
    }) || 0

  const productList = products?.allProducts?.nodes || []
  const medicineBody = parseMedicines({ medicines, productList, type: "Make" })
  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest({
      flow: "epharmacy",
      details: state,
      fileArray: uploadedDocuments,
      templateConfirmation: orderConfirmation,
      templateZendesk: orderTicketBody,
      templateTextMessage: orderTextMessageTemplate,
      callback: async () => {
        setLoading(false)
        navigate("/epharmacy/completed")
      },
      errorCallback: handleErrorSubmit,
      route,
      products,
      deliveryFee,
      medicineBody,
    })
  }

  const handleErrorSubmit = () => {
    setLoading(false)
    navigate("/epharmacy/completed")
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Section
        title="Patient Details"
        addOns={{
          right: <EditDetailsButton route="/epharmacy/checkout" />,
        }}
      >
        <PatientDetailsSummary
          data={epharmacy}
          deliveryAddress={deliveryAddress}
          flow="epharmacy"
        />
      </Section>
      <UploadDocumentsSummary
        files={uploadedDocuments}
        route="/epharmacy/upload"
      />

      <MedsOrderedSummary
        medicines={medicines}
        route="/epharmacy/order"
        deliveryFee={deliveryFee}
      />
      <OrderSubtotalSummary
        province={deliveryAddress?.province?.value}
        medicines={medicines}
        coupon={coupon}
        courier={epharmacy.courierOption}
        deliveryFee={deliveryFee}
      />
      {epharmacy?.orderNotes && (
        <OrderNotesSummary
          route="/epharmacy/checkout"
          notes={epharmacy?.orderNotes}
        />
      )}
      <ConsentAndAuthorization
        termsAndConditionsData={termsAndConditionsData}
        handleOnSubmit={handleOnSubmit}
        flow="epharmacy"
        additionalCheckboxes={
          <FormCheckbox
            name="agreeContactPerson"
            values={epharmacy.agreeContactPerson}
            options={[agreeContactPersonBlurb]}
            isRequired
            onChange={(event) =>
              dispatch({
                type: `SAVE_AGREE_CONTACT_PERSON`,
                payload: event.target.checked ? [agreeContactPersonBlurb] : [],
              })
            }
          />
        }
      >
        {/* <div className="notification is-warning has-text-centered is-size-6 mt-3">
          Order is not final until verified by our pharmacist via SMS.
        </div> */}
        <ActionButtons
          hasCaptcha={true}
          submit={{
            label: "Place Order",
            loading: loading,
            disabled: shouldBeDisabled({ formData: epharmacy, medicines }),
          }}
          back={{
            label: "Back",
            link: proofOfPayment?.path
              ? `${baseRoute}/proof-of-payment`
              : backRoute,
          }}
        />
      </ConsentAndAuthorization>
    </Fragment>
  )
}

export default Summary
